import { graphql, Link, useStaticQuery } from "gatsby";
import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Index } from "elasticlunr";
import { Transition } from "react-transition-group";
import classNames from "classnames";
import { SearchInput, TextInputVariant, Text } from "@qwilr/kaleidoscope";
import { forceReflow, getPortalRoot } from "@qwilr/kaleidoscope/utils";
import { Styles } from "@qwilr/kaleidoscope/icons";
import { ReactComponent as Components } from "assets/components.svg";
import { ReactComponent as Foundations } from "assets/foundations.svg";

interface ISearchProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const Search: FC<ISearchProps> = ({ open, setOpen }) => {
  const [searchIndex, setSearchIndex] = useState<any>();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [resultFocusIndex, setResultFocusIndex] = useState(0);
  const overlayRef = useRef<HTMLInputElement>(null);
  const resultsRef = useRef<HTMLDivElement>(null);
  const portalRoot = useRef(getPortalRoot("search-root"));

  const data = useStaticQuery(graphql`
    query SearchIndexQuery {
      siteSearchIndex {
        index
      }
    }
  `);

  // Initialize search index
  useEffect(() => {
    setSearchIndex(Index.load(data.siteSearchIndex.index));
  }, [data]);

  // Listen for keyboard events
  useEffect(() => {
    const handleSearchToggle = (event: KeyboardEvent) => {
      if (event.key === "k" && event.metaKey) {
        event.preventDefault();
        setOpen(!open);
      }

      if (event.key === "Escape") {
        setOpen(false);
      }
    };

    document.addEventListener("keydown", handleSearchToggle);

    return () => {
      document.removeEventListener("keydown", handleSearchToggle);
    };
  }, [open]);

  // Manage focus
  useEffect(() => {
    let prevFocusElement: HTMLElement;

    if (open) {
      // This is a bit of a hack to focus the input. Consider forwarding refs to `SearchInput`
      const inputElement = overlayRef.current?.querySelector("input");

      prevFocusElement = document.activeElement as HTMLElement;
      inputElement?.focus();
    }

    return () => {
      prevFocusElement?.focus();
    };
  }, [open]);

  const handleSearch = (value: string) => {
    const results = searchIndex
      .search(value, { expand: true })
      .map(({ ref }: { ref: string }) => searchIndex.documentStore.getDoc(ref))
      .filter(({ slug, hidden }) => {
        return !slug.includes("_template") && !hidden;
      });

    setQuery(value);
    setResults(results);
  };

  const handleSearchKeydown = (event: React.KeyboardEvent<HTMLElement>) => {
    const { length } = results;
    const prevIndex = (resultFocusIndex - 1 + length) % length;
    const nextIndex = (resultFocusIndex + 1) % length;
    const resultItems = resultsRef.current?.childNodes;

    if (event.key === "ArrowDown") {
      event.preventDefault();
      setResultFocusIndex(nextIndex);
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setResultFocusIndex(prevIndex);
    } else if (event.key === "Enter") {
      const currentItem = resultItems?.[resultFocusIndex] as HTMLElement;
      currentItem?.click();
    }
  };

  const clearSearch = () => {
    setQuery("");
    setResults([]);
    setResultFocusIndex(0);
  };

  const close = () => setOpen(false);

  const renderCategoryIcon = (category: string): ReactNode => {
    switch (category) {
      case "design":
        return <Styles />;
      case "foundations":
        return <Foundations />;
      case "components":
        return <Components />;
      default:
        return null;
    }
  };

  return (
    <Transition mountOnEnter unmountOnExit in={open} timeout={400} onEnter={forceReflow} onExited={clearSearch}>
      {(status) => (
        <>
          {!!portalRoot.current &&
            createPortal(
              <div className={classNames("kld-search", `kld-search--${status}`)}>
                <div
                  className={classNames("kld-search__backdrop", `kld-search__backdrop--${status}`)}
                  onClick={close}
                />
                <div className="kld-search__overlay-frame">
                  <div className={classNames("kld-search__overlay", `kld-search__overlay--${status}`)} ref={overlayRef}>
                    <div className="kld-search__input">
                      <SearchInput
                        variant={TextInputVariant.Borderless}
                        label="Search docs"
                        placeholder="Search docs"
                        value={query}
                        onChange={handleSearch}
                        onClear={clearSearch}
                        onKeyDown={handleSearchKeydown}
                      />
                    </div>
                    {!!results.length && (
                      <div className="kld-search__results" ref={resultsRef}>
                        {results.map(({ slug, title, id, category }, index) => (
                          <Link
                            className={classNames("kld-search__result-link", {
                              "kld-search__result-link--active": resultFocusIndex === index,
                            })}
                            key={id}
                            to={slug}
                            onClick={close}
                          >
                            {renderCategoryIcon(category)}
                            <Text size="m">{title}</Text>
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>,
              portalRoot.current,
            )}
        </>
      )}
    </Transition>
  );
};

export default Search;
