import React, { FC, useEffect } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import classNames from "classnames";
import useMainContext from "../../hooks/useMainContext";
import { handleMouseUpByBlurring } from "@qwilr/kaleidoscope/utils";
import { formatPostsByCategory } from "../../utils/posts";
import { Transition } from "react-transition-group";
import { AnimationDuration } from "@qwilr/kaleidoscope";
import useWindowSize from "../../hooks/useWindowSize";
import { forceReflow } from "@qwilr/kaleidoscope/utils";
import { IconButton, Text, Label } from "@qwilr/kaleidoscope";
import { ReactComponent as Medium } from "../../assets/medium.svg";
import { ReactComponent as Twitter } from "../../assets/twitter.svg";
import { ReactComponent as LinkedIn } from "../../assets/linkedin.svg";
import { deprecated } from "mobx/lib/internal";
import { Pill } from "../Pill";

interface ISidebarProps {
  className?: string;
}

const Sidebar: FC<ISidebarProps> = ({ className }) => {
  const { category, pathname, sidebarOpen, setSidebarOpen, setHasSidebar } = useMainContext();
  const { width } = useWindowSize();
  const isMobile = width < 1200;

  const data = useStaticQuery(graphql`
    query SidebarQuery {
      allMdx {
        nodes {
          frontmatter {
            title
            deprecated
            hidden
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
          slug
        }
      }
    }
  `);

  const navItems = formatPostsByCategory(data.allMdx.nodes, category);

  useEffect(() => {
    setHasSidebar(true);

    return () => {
      setHasSidebar(false);
    };
  }, []);

  return (
    <Transition
      mountOnEnter
      unmountOnExit
      onEnter={forceReflow}
      in={!isMobile || sidebarOpen}
      timeout={AnimationDuration.Long}
    >
      {(status) => (
        <div className={classNames("kld-sidebar", `kld-sidebar--${status}`, className)}>
          <nav className="kld-sidebar__nav">
            {navItems.map(({ name, posts }) => (
              <div className="kld-sidebar__nav-group" key={name}>
                {name !== "_uncategorized" && (
                  <Label secondary className="kld-sidebar__nav-group-label" size="s">
                    {name}
                  </Label>
                )}
                {posts.map(({ title, url, deprecated }) => (
                  <Link
                    key={url}
                    className="kld-sidebar__nav-link"
                    to={url}
                    aria-current={url === pathname || `${url}/` === pathname}
                    onMouseUp={handleMouseUpByBlurring}
                    onClick={() => setSidebarOpen(false)}
                  >
                    <Text strong className="kld-sidebar__nav-link-text" size="m">
                      {title}
                    </Text>
                    {deprecated && <Pill size="s">Deprecated</Pill>}
                  </Link>
                ))}
              </div>
            ))}
          </nav>
          <div className="kld-sidebar__footer">
            <div className="kld-sidebar__footer-links">
              <IconButton
                className="kld-sidebar__footer-link"
                aria-label="Medium"
                icon={<Medium />}
                href={() => "https://medium.com/kaleidoscope"}
              />
              <IconButton
                className="kld-sidebar__footer-link"
                aria-label="Twitter"
                icon={<Twitter />}
                href={() => "https://twitter.com/qwilr"}
              />
              <IconButton
                className="kld-sidebar__footer-link"
                aria-label="LinkedIn"
                icon={<LinkedIn />}
                href={() => "https://www.linkedin.com/company/qwilr/"}
              />
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
};

export default Sidebar;
