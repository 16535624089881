import { Label } from "@qwilr/kaleidoscope";
import React, { ReactNode } from "react";
import classNames from "classnames";

interface PillProps {
  size?: "s" | "m" | "l";
  children: ReactNode;
  className?: string;
}

export const Pill = ({ children, size = "m", className }: PillProps) => {
  return (
    <Label className={classNames("kld-pill", className)} size={size}>
      {children}
    </Label>
  );
};
