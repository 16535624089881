// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-asset-asset-tsx": () => import("./../../../src/layouts/Asset/Asset.tsx" /* webpackChunkName: "component---src-layouts-asset-asset-tsx" */),
  "component---src-layouts-post-post-tsx": () => import("./../../../src/layouts/Post/Post.tsx" /* webpackChunkName: "component---src-layouts-post-post-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

