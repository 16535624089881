interface PostItem {
  title: string;
  group: string;
  url: string;
  deprecated?: boolean;
}

interface PostGroup {
  name: string;
  posts: PostItem[];
}

export function formatPostsByCategory(nodes: any, category?: string): PostGroup[] {
  const posts = nodes
    .filter((node: any) => {
      return category ? node.parent.relativeDirectory.includes(category) : true;
    })
    .filter((node: any) => {
      return !node.slug.includes("_template") && !node.frontmatter.hidden;
    })
    .map(
      (node: any): PostItem => ({
        title: node.frontmatter.title,
        group: node.parent.relativeDirectory.split("/")[1],
        url: `/${node.slug}`,
        deprecated: !!node.frontmatter.deprecated,
      }),
    )
    .sort((a: PostItem, b: PostItem) => {
      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;
      return 0;
    })
    .reduce((acc: PostGroup[], curr: PostItem): PostGroup[] => {
      if (curr.group) {
        const currentGroup = acc.find((group) => group.name === curr.group);

        if (!currentGroup) {
          acc.push({ name: curr.group, posts: [curr] });
        } else {
          currentGroup.posts.push(curr);
        }

        return acc;
      } else {
        const groupName = "_uncategorized";
        const uncategorizedGroup = acc.find((group) => group.name === groupName);

        if (!uncategorizedGroup) {
          acc.push({ name: groupName, posts: [curr] });
        } else {
          uncategorizedGroup.posts.push(curr);
        }
        return acc;
      }
    }, [])
    .sort((a: PostGroup, b: PostGroup) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

  return posts;
}

export function flattenPosts(formattedPosts: PostGroup[]): PostItem[] {
  return formattedPosts.reduce((acc, curr) => {
    return [...acc, ...curr.posts];
  }, []);
}

interface CategoryLink {
  name: string;
  url: string;
}

export function getFirstPostsByCategory(nodes: any): { [category: string]: CategoryLink } {
  const categoryLinks = nodes
    .filter((node) => !node.frontmatter.hidden)
    .reduce((acc: CategoryLink[], curr: any) => {
      const category = curr.parent.relativeDirectory.split("/")[0];
      if (!acc[category]) {
        const categorized = formatPostsByCategory(nodes, category);
        const links = flattenPosts(categorized);
        return { ...acc, [category]: links[0] };
      }

      return acc;
    }, {});

  return categoryLinks;
}
