import React, { FC } from "react";
import classNames from "classnames";
import { graphql, useStaticQuery, Link } from "gatsby";
import { Close, Menu, Search } from "@qwilr/kaleidoscope/icons";
import { IconButton, TooltipPosition, Label, Text } from "@qwilr/kaleidoscope";
import useMainContext from "../../hooks/useMainContext";
import { handleMouseUpByBlurring } from "@qwilr/kaleidoscope/utils";
import { getFirstPostsByCategory } from "../../utils/posts";
import { ReactComponent as Github } from "../../assets/github.svg";
import useWindowSize from "../../hooks/useWindowSize";

interface INavbarProps {
  className?: string;
  toggleSearch: () => void;
}

const Navbar: FC<INavbarProps> = ({ className, toggleSearch }) => {
  const { category, sidebarOpen, setSidebarOpen, hasSidebar } = useMainContext();
  const { width } = useWindowSize();
  const isMobile = width < 1200;

  const data = useStaticQuery(graphql`
    query NavbarQuery {
      allMdx {
        nodes {
          frontmatter {
            title
            hidden
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
          slug
        }
      }
      allNavbarYaml {
        nodes {
          label
        }
      }
    }
  `);

  const categoryLinks = getFirstPostsByCategory(data.allMdx.nodes);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  return (
    <header className={classNames("kld-navbar", className)}>
      <Link className="kld-navbar__home-link" to="/" onMouseUp={handleMouseUpByBlurring}>
        <NavBarLogo />
        <Text strong size="m" className="kld-navbar__title">
          Kaleidoscope
        </Text>
      </Link>
      <nav className="kld-navbar__nav">
        {data.allNavbarYaml.nodes.map(({ label }: { label: string }) => (
          <Link
            className="kld-navbar__nav-link"
            to={categoryLinks[label.toLowerCase()].url}
            aria-current={category === label.toLowerCase()}
            onMouseUp={handleMouseUpByBlurring}
            key={label}
          >
            <Label className="kld-navbar__nav-link-text" element="span" secondary={category !== label.toLowerCase()}>
              {label}
            </Label>
          </Link>
        ))}
      </nav>
      <div className="kld-navbar__actions">
        <IconButton
          href={() => "https://github.com/qwilr/kaleidoscope-components"}
          icon={<Github className="kl-icon" />}
          tooltip={{ content: "Github repo", position: TooltipPosition.Bottom }}
        />
        <IconButton
          onClick={toggleSearch}
          icon={<Search />}
          tooltip={{ content: "Search (⌘+K)", position: TooltipPosition.Bottom }}
        />
        {isMobile && hasSidebar && (
          <IconButton
            className="kld-navbar__sidebar-toggle"
            aria-pressed={sidebarOpen}
            icon={
              <div className="kld-navbar__sidebar-toggle-icons">
                <Menu className="kld-navbar__sidebar-toggle-icon kld-navbar__sidebar-toggle-icon--menu" />
                <Close className="kld-navbar__sidebar-toggle-icon kld-navbar__sidebar-toggle-icon--close" />
              </div>
            }
            aria-label="Menu"
            onClick={toggleSidebar}
          />
        )}
      </div>
    </header>
  );
};

const NavBarLogo: FC = () => (
  <svg
    className="kld-navbar__logo"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    fill="none"
    viewBox="0 0 64 64"
  >
    <path fill="#FF6161" d="M8.782 9.984A32 32 0 0 0 9.96 55.203l12.288-3.955L8.782 9.984Z" />
    <path fill="#FFCE53" d="M32 0C19.094 0 7.974 7.642 2.913 18.646l8.007 11.096L46.803 3.63A31.86 31.86 0 0 0 32 0Z" />
    <path fill="#726FEE" d="m46.008 47.257-42.176-.062a32.022 32.022 0 0 0 42.224 13.56l-.048-13.498Z" />
    <path
      fill="#00A99D"
      d="m63.38 25.714-13.146-4.232L36.458 63.69C52.021 61.52 64 48.16 64 32c0-2.11-.207-4.216-.62-6.286Z"
    />
    <path
      fill="#3DB0FC"
      d="m34.501.098-8.074 11.19 36.971 26.909c.4-2.041.602-4.117.602-6.197C64 15.17 51.005 1.374 34.5.098Z"
    />
    <path
      fill="#FC4F2E"
      d="m8.783 9.984-.128.141-.239.256-.224.236c-.075.08-.168.187-.256.281-.107.128-.212.244-.319.368l-.245.29-.204.264c-.067.08-.128.161-.197.243l-.218.275-.185.242c-.127.165-.251.331-.374.5l-.178.232-.218.308c-.05.074-.103.147-.154.222-.073.105-.145.21-.217.318l-.138.207c-.151.229-.3.46-.445.693l-.068.106-.215.354c-.04.066-.08.128-.118.197l-.21.359-.102.18c-.233.413-.457.83-.672 1.253-.031.058-.06.117-.09.175-.065.128-.128.262-.193.394a6.408 6.408 0 0 0-.078.164 13.73 13.73 0 0 0-.19.402l8.007 11.096 3.484-2.536-5.616-17.22Z"
    />
    <path
      fill="#378C55"
      d="m46.44 3.44-.102-.053a30.668 30.668 0 0 0-1.172-.56l-.134-.06a39.257 39.257 0 0 0-.67-.29l-.14-.058-.366-.148-.19-.075c-.192-.075-.384-.15-.581-.221l-.24-.088-.331-.118-.421-.144-.351-.113c-.108-.034-.215-.068-.323-.1l-.279-.086c-.175-.053-.35-.102-.527-.152l-.256-.07c-.143-.04-.287-.076-.431-.113l-.224-.057a20.069 20.069 0 0 0-.55-.128l-.215-.051a24.578 24.578 0 0 0-.609-.128l-.114-.022A40.086 40.086 0 0 0 37.648.5L37.49.471a36.607 36.607 0 0 0-.654-.106l-.113-.017c-.209-.03-.417-.059-.627-.086l-.083-.006c-.23-.028-.464-.055-.697-.08l-.103-.01A32.632 32.632 0 0 0 34.5.104l-8.074 11.183 4.925 3.584L46.803 3.625l-.362-.186Z"
    />
    <path
      fill="#084992"
      d="m46.008 47.256-4.184-.006-5.366 16.439c.203-.028.404-.059.603-.09l.2-.033c.188-.03.376-.064.563-.098.074-.013.148-.029.223-.043.187-.035.373-.071.56-.111l.128-.027c.382-.085.76-.173 1.136-.265l.193-.046.483-.128.304-.086c.151-.043.301-.087.45-.128.113-.033.225-.069.336-.103.142-.045.286-.09.426-.137.141-.048.256-.087.384-.128.207-.072.412-.145.615-.22.15-.056.302-.11.452-.168l.35-.138.409-.167.341-.144.425-.187c.104-.046.209-.092.313-.141l.542-.256.163-.078-.049-13.51Z"
    />
    <path
      fill="#702B5B"
      d="M20.936 47.22 3.84 47.196c.086.16.173.319.256.478l.08.14c.09.158.182.315.275.473l.116.193c.114.19.23.384.347.566l.082.128c.1.158.201.314.305.468l.119.18c.128.187.256.37.384.554l.079.11c.111.156.224.312.337.466l.128.165c.134.182.27.361.41.539l.073.095c.128.154.246.308.372.462.04.05.08.1.128.15.145.176.291.351.44.524l.07.08c.127.152.268.304.404.455l.128.136c.156.171.312.34.472.512l.063.065c.143.151.289.3.436.448l.128.128c.165.164.332.328.5.488l.013.013 12.288-3.956-1.337-4.034Z"
    />
    <path
      fill="#0F759B"
      d="M63.99 31.201v-.143a27.87 27.87 0 0 0-.025-.655c0-.034 0-.067-.006-.1a30.9 30.9 0 0 0-.042-.698v-.04a32.044 32.044 0 0 0-.528-3.85l-13.155-4.233-1.882 5.76 15.046 10.955a32.56 32.56 0 0 0 .361-2.265v-.025c.03-.237.055-.475.08-.713 0-.023 0-.046.006-.068.022-.223.041-.445.058-.668 0-.039.006-.077.009-.115.015-.21.028-.419.038-.629 0-.051.006-.102.01-.154.01-.203.016-.407.022-.61 0-.058 0-.114.007-.172 0-.256.009-.512.009-.778s.002-.532-.008-.799Z"
    />
  </svg>
);

export default Navbar;
